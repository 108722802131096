import './InCollectionSearch.scss';

import Button from '@components/Button';
import ButtonTextX from '@components/ButtonTextX';
import PlaceholderItem from '@components/PlaceHolderItem';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useTranslation from '@hooks/useTranslation';
import { useAdditionalElementState } from '@providers/AdditionalElementProvider';
import { useFilterState } from '@providers/FilterProvider';
import type { ModifiersTypeMap } from '@utils';
import { createClsNameMap, getTermValueFromUrl, mapModifiers } from '@utils';
import { debounce } from 'lodash-es';
import type { ChangeEvent } from 'react';
import { useRef, useState } from 'react';

export const clsNameMap = createClsNameMap({
  elements: {
    input: createClsNameMap(),
    'btn-group': createClsNameMap(),
  },
  modifiers: ['vertical', 'horizontal'],
})('in-collection-search');

const IconSearch = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.0707 2.24494C5.96022 1.65058 7.00601 1.33334 8.07582 1.33334C9.51036 1.33343 10.8861 1.90334 11.9005 2.91771C12.9149 3.93208 13.4848 5.30784 13.4849 6.74237C13.4849 7.81219 13.1677 8.858 12.5733 9.74752C11.9789 10.637 11.1342 11.3303 10.1458 11.7397C9.1574 12.1491 8.06982 12.2562 7.02056 12.0475C5.97131 11.8388 5.0075 11.3237 4.25103 10.5672C3.49456 9.81072 2.97939 8.84692 2.77068 7.79766C2.56197 6.74841 2.66909 5.66082 3.07849 4.67245C3.48789 3.68407 4.18119 2.83929 5.0707 2.24494ZM8.07582 2.33334C9.24515 2.33343 10.3666 2.79798 11.1934 3.62482C12.0203 4.45166 12.4848 5.57308 12.4849 6.74241M8.07582 2.33334C7.2038 2.33335 6.35133 2.59194 5.62627 3.0764C4.90121 3.56088 4.33608 4.24948 4.00237 5.05513C3.66866 5.86078 3.58135 6.7473 3.75147 7.60257C3.9216 8.45785 4.34152 9.24347 4.95814 9.86008C5.57476 10.4767 6.36038 10.8966 7.21565 11.0667C8.07093 11.2369 8.95745 11.1496 9.7631 10.8158C10.5687 10.4821 11.2574 9.91702 11.7418 9.19195C12.2263 8.46689 12.4849 7.61443 12.4849 6.74241" fill="#31373D"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.3847 10.0514C11.58 9.85615 11.8966 9.85615 12.0918 10.0514L15.5203 13.4799C15.7155 13.6751 15.7155 13.9917 15.5203 14.187C15.325 14.3822 15.0084 14.3822 14.8132 14.187L11.3847 10.7585C11.1895 10.5633 11.1895 10.2467 11.3847 10.0514Z" fill="#31373D"/>
</svg>
`;

export type InCollectionSearchProps = {
  layoutModifiers: ModifiersTypeMap<typeof clsNameMap>;
  isHide: boolean;
};

const InCollectionSearch = ({ layoutModifiers, isHide }: InCollectionSearchProps) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const { t } = useTranslation();

  const { addInCollectionSearchParams } = useFilterState();
  const { loadingAdditional } = useAdditionalElementState();
  const { enableCollectionSearch } = window?.boostSDAppConfig?.additionalElementSettings || {};

  const {
    generalSettings: { termKey },
  } = useGeneralSettings();

  if (!enableCollectionSearch || isHide) return <></>;

  const currentCollectionSearchParam = getTermValueFromUrl(termKey);

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    addInCollectionSearchParams(e.target.value);
  }, 400);

  const placeholderValue =
    layoutModifiers.vertical || layoutModifiers.horizontal
      ? t('inCollectionSearch')
      : t('searchOptions');

  const inputRef = useRef<HTMLInputElement>(null);

  const resetInputField = () => {
    addInCollectionSearchParams('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  if (loadingAdditional && firstLoad) {
    return <PlaceholderItem modifiers={['in-collection']} />;
  }

  if (firstLoad) {
    setFirstLoad(false);
  }

  return (
    <div className={mapModifiers(clsNameMap, layoutModifiers)}>
      <input
        aria-label={currentCollectionSearchParam}
        onChange={handleChange}
        className={clsNameMap.elm('input')}
        defaultValue={currentCollectionSearchParam}
        placeholder={placeholderValue}
        ref={inputRef}
      />

      <div className={clsNameMap.elm('btn-group')}>
        {currentCollectionSearchParam !== '' && (
          <ButtonTextX
            value={'Clear'}
            modifiers={['clear-input-collection-search']}
            onClick={resetInputField}
          />
        )}
        <Button
          aria-label={t('searchOptions')}
          type='submit'
          icon={IconSearch}
          buttonModifiers={['as-in-collection-search']}
        />
      </div>
    </div>
  );
};

export default InCollectionSearch;
