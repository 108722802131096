import { merge } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useState } from 'react';

import type { SearchSettings, SearchSettingsProviderValue } from '../types';

const SearchSettingsContext = createContext<SearchSettingsProviderValue | null>(null);

export type SearchSettingsProviderProps = PropsWithChildren<unknown>;

const defaultSettings: SearchSettings = {
  /**
   * SearchSettings
   */
  enableFuzzySearch: true,

  /**
   * InstantSearch admin Settings
   */
  enableInstantSearch: true, // enabled/disabled instant search settings - ok
  suggestionStyle: 'style2', // style1 (1 column), style2 (two column), style3 (full-width) - ok

  suggestionStyle1ProductItemType: 'list', // list, grid - ok
  suggestionStyle1ProductPosition: 'none', // Always 'none' - ok
  suggestionStyle1ProductPerRow: 1, // 1 when `list` and 2 when `grid` - ok

  suggestionStyle2ProductItemType: 'list', // list, grid - ok
  suggestionStyle2ProductPosition: 'right', // left, right - ok
  suggestionStyle2ProductPerRow: 2, //  1 or 2 | only type = list => 1/2 product/row - ok

  suggestionStyle3ProductItemType: 'grid', // list, grid - ok
  suggestionStyle3ProductPosition: 'right', // left, right
  suggestionStyle3ProductPerRow: 3, // 2 or 3 only type = list => 2/3 product/row - ok
  showSearchBtnStyle3: true,

  productAvailable: false, // ! out of stocks - ok
  showSuggestionProductVendor: true, // - ok
  showSuggestionProductPrice: true, // - ok
  showSuggestionProductSalePrice: true, // - ok
  showSuggestionProductImage: true, // - ok
  showSuggestionProductSku: false, // - ok

  suggestionBlocks: [
    { type: 'suggestions', label: 'Suggestions', status: 'active', number: 3 },
    { type: 'collections', label: 'Collections', status: 'active', number: 2 },
    { type: 'pages', label: 'Pages', status: 'active', number: 2 },
    { type: 'products', label: 'Products', status: 'active', number: 6 },
  ], // blocks query limit when search api and display settings in admin app - ok

  searchBoxOnclick: {
    recentSearch: {
      label: 'Recent searches',
      status: true,
      number: 3,
    },
    searchTermSuggestion: {
      label: 'Popular searches',
      status: false,
      data: [],
    },
    productSuggestion: {
      label: 'Trending products',
      status: false,
      data: [],
    },
  }, // - ok

  suggestionNoResult: {
    search_terms: {
      label: 'Popular suggestions',
      status: false,
      data: [],
    },
    products: {
      label: 'Products',
      status: false,
      data: [],
    },
  },

  searchPanelBlocks: {
    products: {
      label: 'Products',
      pageSize: 25,
      active: true,
      displayImage: true,
    },
    collections: {
      label: 'Collections',
      pageSize: '25',
      active: false,
      displayImage: true,
      displayDescription: true,
    },
    pages: {
      label: 'Pages',
      pageSize: '1',
      active: false,
      displayImage: true,
      displayExcerpt: true,
    },
    searchEmptyResultMessages: {
      active: false,
      label:
        'We are sorry! We couldn\'t find results for "{{ terms }}".{{ breakline }}But don\'t give up – check the spelling or try less specific search terms.',
    },
    searchTips: {
      label: 'Search tips',
      active: false,
      searchTips:
        'Please double-check your spelling.{{ breakline }}Use more generic search terms.{{ breakline }}Enter fewer keywords.{{ breakline }}Try searching by product type, brand, model number or product feature.',
    },
    searchTermSuggestions: {
      label: 'Check out some of these popular searches',
      active: false,
      type: 'based_on_data',
      searchTermList: [],
    },
    mostPopularProducts: {
      label: 'Trending products',
      active: false,
      type: 'based_on_data',
      productList: [],
    },
  },

  /**
   * InstantSearch others Settings
   */
  suggestionMobileStyle: 'style1', // style1 (1 column full-width), style2 (1 column non-full-width) - ok
  suggestionMobileProductItemType: 'grid',
  showSuggestionLoading: true, // ok
  showSearchBtnMobile: false,
  enableDefaultResult: true, // -ok
  removePriceDecimal: false, // - ok
  highlightSuggestionResult: false, // Highlight Text in the Search result
  openProductNewTab: false, // Open product in the new tab - ok
  suggestionMode: 'prod', // prod or test
  reduceMinMatch: false, // false (default): results to 85% match. true: results to 50% match. An Integer Number: requires min match exact this number - ok
  fullMinMatch: false, // Search results match 100% if the search term has more than 2 words. reduce_min_match will override the full_min_match - ok
  enablePlusCharacterSearch: false, // Enable search with Plus character - ok
  suggestionMaxHeight: 0,
  suggestionDymLimit: 2,
  skipFields: [],

  // move from general
  showVariantsAsProduct: false,
};

export const SearchSettingsProvider = ({ children }: SearchSettingsProviderProps) => {
  const [settings] = useState<SearchSettings>(
    merge(defaultSettings, window.boostSDAppConfig?.searchSettings || {})
  );

  /**
   * Log for debug and fix bug
   */
  console.log('Search settings', settings);

  const providerValue = {
    settings,
  };

  return (
    <SearchSettingsContext.Provider value={providerValue}>
      {children}
    </SearchSettingsContext.Provider>
  );
};

export const useSearchSettings = () => {
  const context = useContext(SearchSettingsContext);
  if (!context) {
    throw Error('Use useSearchSettings in SearchSettingsProvider');
  }

  return context;
};
