import './item.scss';

import { registryComponent } from '@boost-sd/components-registry/registry';
import type { ReactNodeRenderer } from '@components/CustomizableNode';
import CustomizableNode from '@components/CustomizableNode';
import FormatCurrency from '@components/FormatCurrency';
import HightLightText from '@components/HighLightText';
import IntegrationProductRating from '@components/IntegrationProductRating';
import TranslateWithComponent from '@components/ProductPrice/TranslateWithComponent';
import { clsNameMap as suggestionQueriesCls } from '@components/SuggestionQueries';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useTranslation from '@hooks/useTranslation';
import type { ProductListItem } from '@providers/ProductListProvider';
import { useProduct } from '@providers/ProductProvider';
import { addRecentlyViewedProduct } from '@providers/ProductProvider/recentlyViewedProduct';
import { useSearchSettings, useSearchState } from '@providers/SearchProvider';
import {
  buildProductDetailUrlWithVariant,
  createClsNameMap,
  mapModifiers,
  stripHtml,
} from '@utils';
import { renderToString } from 'react-dom/server';

export const clsNameMap = createClsNameMap({
  elements: {
    link: createClsNameMap(),
    product: createClsNameMap({
      modifiers: ['left', 'right'],
    }),
    amount: createClsNameMap({
      modifiers: ['sale', 'regular'],
    }),
    title: createClsNameMap(),
    sku: createClsNameMap(),
    vendor: createClsNameMap(),
    price: createClsNameMap(),
    money: createClsNameMap(),
    img: createClsNameMap(),
    badge: createClsNameMap({
      modifiers: ['sold-out', 'sale'],
    }),
  },
  modifiers: ['product'],
})('suggestion-queries-item');

export type SearchProductItemProps = {
  product: ProductListItem;
  className?: string;
  onRenderProductItem?: ReactNodeRenderer;
  onRenderInfo?: ReactNodeRenderer;
  onRenderSoldOut?: ReactNodeRenderer;
  onRenderSaleLabel?: ReactNodeRenderer;
  onRenderImage?: ReactNodeRenderer;
  onRenderTitle?: ReactNodeRenderer;
  onRenderPrice?: ReactNodeRenderer;
};

const SearchProductItem = (props: SearchProductItemProps) => {
  const { t } = useTranslation();

  const {
    product,
    onRenderProductItem,
    onRenderInfo,
    onRenderSoldOut,
    onRenderSaleLabel,
    onRenderImage,
    onRenderTitle,
    onRenderPrice,
  } = props;

  const {
    generalSettings: { no_image_url, current_tags, addCollectionToProductUrl: hasCollection },
  } = useGeneralSettings();

  const {
    settings: {
      showSuggestionProductPrice,
      showSuggestionProductSalePrice,
      showSuggestionProductImage,
      showSuggestionProductSku,
      showSuggestionProductVendor,
      highlightSuggestionResult,
      openProductNewTab,
      removePriceDecimal,
    },
  } = useSearchSettings();

  const { searchTerm } = useSearchState();

  const { salePercent, saleAmount } = useProduct(product);

  if (!product) return null;

  const buildProductImage = () => {
    return product.images_info.length > 0 ? product.images_info[0].src : no_image_url;
  };

  const buildSKU = () => {
    return Array.isArray(product.skus) && product.skus.length > 0 ? product.skus[0] : '';
  };

  const buildPrice = () => {
    const isShowSalePrice =
      showSuggestionProductSalePrice &&
      !!product.compare_at_price_min &&
      product.compare_at_price_min > product.price_min;

    if (!showSuggestionProductPrice) return <></>;

    return (
      <span
        className={mapModifiers(clsNameMap['amount'], {
          sale: !!isShowSalePrice,
          regular: !isShowSalePrice,
        })}
      >
        <FormatCurrency
          isSale={isShowSalePrice}
          value={product.price_min}
          removePriceDecimal={removePriceDecimal}
        />

        {isShowSalePrice && (
          <FormatCurrency
            isPriceCompare
            value={product.compare_at_price_min}
            removePriceDecimal={removePriceDecimal}
          />
        )}
      </span>
    );
  };

  return (
    <li
      className={`${suggestionQueriesCls.elm('item')} ${mapModifiers(clsNameMap, ['product'])}`}
      aria-label={`products: ${product.title}`}
      aria-selected={'false'}
      role={'option'}
      data-id={product.id}
      data-title={product.title}
    >
      <CustomizableNode renderer={onRenderProductItem}>
        <a
          className={clsNameMap.elm('link')}
          tabIndex={-1}
          href={buildProductDetailUrlWithVariant(product, hasCollection, current_tags)}
          target={openProductNewTab ? '_blank' : '_parent'}
          rel='noreferrer'
          onClick={() => addRecentlyViewedProduct(product.id)}
        >
          {showSuggestionProductImage && (
            <div className={mapModifiers(clsNameMap.product, { left: true })}>
              {/* Out of stocks  */}
              {!product.available && (
                <CustomizableNode renderer={onRenderSoldOut}>
                  <div className={mapModifiers(clsNameMap.badge, { 'sold-out': true })}>
                    {t('productItem.productItemSoldOut')}
                  </div>
                </CustomizableNode>
              )}

              {product.available && product.percent_sale_min && product.percent_sale_min > 0 ? (
                <CustomizableNode renderer={onRenderSaleLabel}>
                  <div className={mapModifiers(clsNameMap.badge, { sale: true })}>
                    <TranslateWithComponent
                      translation='productItem.productItemSale'
                      data={{
                        salePercent,
                        saleAmount: renderToString(<FormatCurrency value={saleAmount} />),
                      }}
                    />
                  </div>
                </CustomizableNode>
              ) : null}
              <CustomizableNode renderer={onRenderImage}>
                <img
                  className={clsNameMap.elm('img')}
                  src={buildProductImage()}
                  alt={product.title}
                />
              </CustomizableNode>
            </div>
          )}

          <CustomizableNode renderer={onRenderInfo}>
            <div className={mapModifiers(clsNameMap.product, { right: true })}>
              <CustomizableNode renderer={onRenderTitle}>
                <p className={clsNameMap.elm('title')}>
                  <HightLightText
                    text={stripHtml(product.title)}
                    highlight={searchTerm}
                    enableHighLight={highlightSuggestionResult}
                  />
                </p>
              </CustomizableNode>
              {showSuggestionProductSku && buildSKU() && (
                <div className={clsNameMap.elm('sku')}>SKU: {buildSKU()}</div>
              )}
              {showSuggestionProductVendor && product.vendor && (
                <div className={clsNameMap.elm('vendor')}>{product.vendor}</div>
              )}
              <IntegrationProductRating />
              <CustomizableNode renderer={onRenderPrice}>
                <p className={clsNameMap.elm('price')}>{buildPrice()}</p>
              </CustomizableNode>
            </div>
          </CustomizableNode>
        </a>
      </CustomizableNode>
    </li>
  );
};

export default registryComponent('SearchProductItem', SearchProductItem);
