import { SearchAPI } from '@apis/search';
import type {
  SearchResultPanelCollectionContent,
  SearchResultPanelPageContent,
} from '@components/SearchResultPanelContent/types';
import { ALL_REDIRECTS_KEY } from '@constants/instantSearchRedirect';
import useGeneralSettings from '@hooks/useGeneralSettings';
import { BOOST_SD_SEARCH_RESULTS_CACHE } from '@hooks/useLocalStorage';
import useMountEffect from '@hooks/useMountEffect';
import type { ProductListItem } from '@providers/ProductListProvider';
import type { Dict } from '@types';
import {
  getLocalStorage,
  getTermValueFromUrl,
  isBadSearchTerm,
  isSearchPage,
  setLocalStorage,
  stripHtml,
} from '@utils';
import { debounce } from 'lodash-es';
import type { ReactNode } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import type { SearchRedirectResponse, SearchStateValue } from '../types';
import { useSearchSettings } from './SearchSettings';

export type SearchStateProviderProps = {
  children: ReactNode;
};

const SearchStateContext = React.createContext<SearchStateValue | null>(null);

const SearchStateProvider = ({ children }: SearchStateProviderProps) => {
  const { settings: searchSettings } = useSearchSettings();
  const {
    searchBoxOnclick,
    suggestionNoResult,
    showSuggestionLoading,
    productAvailable,
    searchPanelBlocks: {
      collections: { pageSize: collectionLimit },
      pages: { pageSize: pageLimit },
    },
  } = searchSettings;
  const {
    generalSettings: { customizeSearchParams, termKey },
  } = useGeneralSettings();

  const [searchTerm, setSearchTerm] = useState<string>(getTermValueFromUrl(termKey));
  const [searchResult, setSearchResult] = useState<Dict>({});
  const [trendingProducts, setTrendingProducts] = useState<Array<ProductListItem>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingMostProduct, setIsLoadingMostProduct] = useState<boolean>(false);
  const [mostProducts, setMostProduct] = useState<ProductListItem[]>([]);
  const [mostSearchTerms, setMostSearchTerms] = useState<string[]>([]);
  const [currentSearchContentResultActive, setCurrentSearchContentResultActive] =
    useState<string>('');
  const [isDirty, setIsDirty] = useState(false);
  const [searchResultPanelCollections, setSearchResultPanelCollections] = useState<{
    collections: SearchResultPanelCollectionContent[];
    total_collection: number;
  }>({ collections: [], total_collection: 0 });
  const [currentSearchPanelCollectionsPage, setCurrentSearchPanelCollectionsPage] =
    useState<number>(1);
  const [searchResultPanelPages, setSearchResultPanelPages] = useState<{
    pages: SearchResultPanelPageContent[];
    total_page: number;
  }>({ pages: [], total_page: 0 });
  const [currentSearchPanelPagesPage, setCurrentSearchPanelPagesPage] = useState<number>(1);
  const [searchRedirects, setSearchRedirects] = useState<Dict>({});

  useMountEffect(function initFirstData() {
    // get trending products if enabled
    const { status, data } = searchBoxOnclick.productSuggestion;
    if (status) {
      SearchAPI.getProductsByIds(data, { productAvailable }).then((res: Array<ProductListItem>) => {
        setTrendingProducts(res);
      });
    }

    // get most suggestion term if enabled
    const _mostSearchTermsSetting = suggestionNoResult.search_terms;
    if (_mostSearchTermsSetting.status) {
      setMostSearchTerms(_mostSearchTermsSetting.data);
    }

    // get redirects
    getRedirects(searchBoxOnclick.searchTermSuggestion.data);
  });

  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm]);

  const debounceInputChange = useCallback(
    debounce((searchQuery: string) => {
      if (!searchQuery) {
        setSearchResult({});
      }

      const searchTerm = stripHtml(searchQuery.trim());
      setSearchTerm(searchTerm);

      // get redirects
      if (searchTerm) {
        getRedirects([searchTerm]);
      } else {
        getRedirects(searchBoxOnclick.searchTermSuggestion.data);
      }
    }, 300),
    []
  );

  const handleResponse = (searchQuery: string, res: Dict) => {
    // handle redirects
    if (res.redirect) {
      const _direct = {
        title: res.query,
        url: res.redirect,
      };

      // remove if suggestion have word redirect
      res.suggestions = res.suggestions.filter((item: Dict | string) => item !== res.query);

      // push new redirect to suggestion
      res.suggestions?.push(_direct);
    }

    // save global search
    setLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE, {
      ...getLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE),
      [searchQuery]: res,
    });

    // save search data
    setSearchResult(res);

    // improve logic, if allEmpty = true -> check call api trending product
    if (res.all_empty) {
      // get most product if enabled
      const _mostProductsSetting = suggestionNoResult.products;
      if (_mostProductsSetting.status) {
        setIsLoadingMostProduct(true);

        SearchAPI.getProductByHandle(_mostProductsSetting.data, searchQuery, { productAvailable })
          .then((res: Array<ProductListItem>) => {
            setMostProduct(res);
            setIsLoadingMostProduct(false);
          })
          .catch((e) => setIsLoadingMostProduct(false));
      }
    }

    showSuggestionLoading && setIsLoading(false);
  };

  const getRedirects = (terms: Array<string>) => {
    SearchAPI.redirects(terms).then((res: SearchRedirectResponse) => {
      const redirects: Dict = {};

      res.data?.forEach((redirect) => {
        redirect?.terms?.forEach((term) => {
          redirects[term] = redirect.redirect_to;
        });
      });

      setLocalStorage(ALL_REDIRECTS_KEY, redirects);
      setSearchRedirects(redirects);
    });
  };

  const onSearch = (searchQuery: string) => {
    // before search
    const query = stripHtml(searchQuery).trim();
    if (isBadSearchTerm(query)) {
      setIsLoading(false);
      return;
    }

    const searchResultsCache = getLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE);

    if (searchResultsCache && searchResultsCache[query]) {
      setSearchResult(searchResultsCache[query]);
      showSuggestionLoading && setIsLoading(false);
    } else if (query?.trim()) {
      showSuggestionLoading && !isLoading && setIsLoading(true);

      SearchAPI.get(query, searchSettings, customizeSearchParams)
        .then(async (res: Dict) => {
          handleResponse(query, res);
        })
        .catch((err) => setIsLoading(false));
    } else {
      setSearchResult({});
    }
  };

  const changeSearchTerm = (value: string) => {
    if (!isDirty) {
      setIsDirty(true);
    }

    debounceInputChange(value);
  };

  const getSearchResultPanelData = async (page: number, type: 'collection' | 'page') => {
    const params: Dict = {
      [termKey]: getTermValueFromUrl(termKey),
    };

    const limit = Number(type === 'collection' ? collectionLimit : pageLimit);
    const subRoute = type === 'collection' ? '/collections' : '/pages';

    let _customizeSearchParamWidthSearchPage = {};

    if (isSearchPage()) {
      _customizeSearchParamWidthSearchPage = customizeSearchParams || {};
    }

    return SearchAPI.searchInCollection(params, {
      page,
      limit,
      subRoute,
      customizeSearchParams: _customizeSearchParamWidthSearchPage,
    }).then((res) => {
      if (type === 'collection') {
        const { collections, total_collection } = res;

        setCurrentSearchPanelCollectionsPage(page);
        setSearchResultPanelCollections({ collections, total_collection });
      } else {
        const { pages, total_page } = res;

        setCurrentSearchPanelPagesPage(page);
        setSearchResultPanelPages({ pages, total_page });
      }
    });
  };

  const initSearchResultPanelData = (type: 'collection' | 'page') => {
    getSearchResultPanelData(1, type);
  };

  const providerValue = {
    searchTerm,
    setSearchTerm,
    searchResult,
    changeSearchTerm,
    trendingProducts,
    isLoading,
    isLoadingMostProduct,
    mostProducts,
    mostSearchTerms,
    currentSearchContentResultActive,
    setCurrentSearchContentResultActive,
    isDirty,
    searchResultPanelCollections,
    searchResultPanelPages,
    getSearchResultPanelData,
    initSearchResultPanelData,
    currentSearchPanelCollectionsPage,
    currentSearchPanelPagesPage,
    searchRedirects,
    setSearchRedirects,
  };

  return (
    <SearchStateContext.Provider value={providerValue}>{children}</SearchStateContext.Provider>
  );
};

const useSearchState = (): SearchStateValue => {
  const context = useContext(SearchStateContext);
  if (!context) {
    throw Error('Use useSearchState in SearchStateProvider');
  }

  return context;
};

export { SearchStateProvider, useSearchState };
