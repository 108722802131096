import { registryModule } from '@boost-sd/components-registry/registry';
import { enableProductFilterFallback } from '@boost-sd/fallback-theme';
import { DEFAULT_LOCALE } from '@constants/locale';
import { shouldKeepScrollRightAfterFiltering } from '@providers/FilterProvider';
import type { Dict } from '@types';
import {
  addParamsLocale,
  buildInfoWithRequestId,
  buildRequestIdForProducts,
  getBoostPFSSectionId,
  getCurrentPage,
  isMobileWidth,
} from '@utils';

const { filterUrl } = window.boostSDAppConfig?.api || {};
const PATH = filterUrl || 'https://services.mybcapps.com/bc-sf-filter/filter';

const HTTP_STATUS_NEED_FALLBACK = [404, 403, 500];

type ExtraParams = {
  page?: number;
  limit?: number;
  sort?: string;
  locale?: string;
};

const getEventType = (filterParams: Dict) => {
  if (filterParams.event_type) return filterParams.event_type;

  let eventType = 'init';

  // check existed filter params with prefix pf_
  const keys = Object.keys(filterParams);
  for (let i = 0; i < keys.length; i++) {
    if (keys[i].startsWith('pf_')) {
      return 'filter';
    }
  }

  if (filterParams.pf_c_collection >= 0 || filterParams.collection_scope > 0) {
    eventType = 'collection';
  }

  return eventType;
};

export const FilterAPI = registryModule('FilterAPI', {
  get: async (filterParams: Dict, extraParams: ExtraParams) => {
    let defaultParams: Dict = {
      t: Date.now(),
      _: 'pf',
      shop: window.Shopify?.shop,
      page: extraParams.page ?? 0,
      limit: extraParams.limit ?? 23,
      sort: extraParams.sort ?? 'best-selling',
      locale: extraParams.locale ?? DEFAULT_LOCALE,
      event_type: getEventType(filterParams),
      build_filter_tree: true,
      sid: getBoostPFSSectionId(),
      pg: getCurrentPage(),
    };

    defaultParams = addParamsLocale(defaultParams);

    const params = new URLSearchParams(defaultParams);

    // delete pf_c_collection if it === 0: fix bug when search
    if (filterParams.pf_c_collection === 0) {
      delete filterParams.pf_c_collection;
    }

    if (filterParams) {
      Object.keys(filterParams).forEach((optionId) => {
        const values = filterParams[optionId];
        if (values == null) return;

        if (Array.isArray(values)) {
          values.forEach((value) => {
            // Convert value to TRUE/FALSE for stock status filter option
            if (optionId.indexOf('pf_st_') === 0) {
              value = value === 'in-stock' ? true : false;
            }
            params.append(`${optionId}[]`, value);
          });
        } else {
          params.set(optionId, values);
        }
      });
    }

    const response = await fetch(`${PATH}?${params}`, { method: 'GET' }).then((res) => {
      const { status } = res;
      if (HTTP_STATUS_NEED_FALLBACK.includes(status)) {
        enableProductFilterFallback();
      }

      return res;
    });

    const resData = await response.json();

    // save requestId
    const requestId = response.headers.get('X-Request-ID') || '';
    buildRequestIdForProducts(requestId, resData.products, 'filter', false);
    buildInfoWithRequestId(requestId, {
      query_string: `${params?.toString()}`,
      action: 'filter',
    });

    // only scroll when page === 0 or 1
    if ([0, 1].includes(extraParams.page || 0) && !isMobileWidth())
      shouldKeepScrollRightAfterFiltering();

    return resData;
  },
});
