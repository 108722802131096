import './SearchForm.scss';

import { getQueryParamsState } from '@boost-sd/core-js/history';
import Button from '@components/Button';
import ButtonTextX from '@components/ButtonTextX';
import { getOnClickRecentSearches } from '@constants/instantSearchClick';
import useGeneralSettings from '@hooks/useGeneralSettings';
import useTranslation from '@hooks/useTranslation';
import { useFilterState } from '@providers/FilterProvider/Provider/FilterState';
import { useSearchSettings } from '@providers/SearchProvider';
import { useSearchState } from '@providers/SearchProvider/Provider/SearchState';
import { createClsNameMap, getTermValueFromUrl } from '@utils';
import { isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';

export const clsNameMap = createClsNameMap({
  elements: {
    title: createClsNameMap(),
    wrapper: createClsNameMap(),
    input: createClsNameMap(),
    search: createClsNameMap(),
    scope: createClsNameMap({
      elements: {
        label: createClsNameMap(),
        item: createClsNameMap(),
      },
    }),
  },
})('search-form');

const IconSearch = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33335 9.16601C3.33335 5.94435 5.94503 3.33268 9.16669 3.33268C12.3883 3.33268 15 5.94435 15 9.16601C15 10.7377 14.3784 12.1642 13.3677 13.2132C13.3392 13.235 13.3119 13.259 13.2858 13.2851C13.2597 13.3112 13.2357 13.3386 13.2138 13.367C12.1649 14.3778 10.7384 14.9993 9.16669 14.9993C5.94503 14.9993 3.33335 12.3877 3.33335 9.16601ZM13.8481 15.0259C12.565 16.0523 10.9375 16.666 9.16669 16.666C5.02455 16.666 1.66669 13.3082 1.66669 9.16601C1.66669 5.02388 5.02455 1.66602 9.16669 1.66602C13.3088 1.66602 16.6667 5.02388 16.6667 9.16601C16.6667 10.9369 16.053 12.5644 15.0266 13.8474L18.0893 16.9101C18.4147 17.2356 18.4147 17.7632 18.0893 18.0886C17.7639 18.4141 17.2362 18.4141 16.9108 18.0886L13.8481 15.0259Z" fill="white"></path>
</svg>
`;

const SearchForm = () => {
  const { t } = useTranslation();

  const {
    generalSettings: { termKey },
  } = useGeneralSettings();

  const { changeSearchTerm } = useSearchState();

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [inputValue, setInputValue] = useState(getTermValueFromUrl(termKey));
  const { removeFilterParams, scopedFilterParams } = useFilterState();
  const searchTerm = getQueryParamsState()?.[termKey];

  const {
    settings: {
      searchBoxOnclick: { recentSearch },
    },
  } = useSearchSettings();

  const searchQueries = getQueryParamsState();

  const getSuggestionScopeParamsFromQuery = () => {
    let type = '';
    let value = '';
    let collectionId = '';

    switch (true) {
      case 'vendors' in searchQueries: {
        type = scopedFilterParams.vendors;
        value = searchQueries['vendors']?.[0];
        break;
      }
      case 'product_types' in searchQueries: {
        type = scopedFilterParams.product_types;
        value = searchQueries['product_types']?.[0];
        break;
      }
      case 'categories' in searchQueries: {
        type = scopedFilterParams.categories;
        value = searchQueries['categories']?.[0];
        break;
      }
      case 'collections' in searchQueries: {
        const recentSearches = getOnClickRecentSearches(recentSearch.number);

        collectionId = searchQueries['collections'][0];

        const collection = recentSearches.find(
          (recentSearch) => recentSearch.extraParam === `collections=${collectionId}`
        );

        type = scopedFilterParams.collections;
        value = collection?.scope;
        break;
      }
    }

    return {
      type,
      value: value?.replace('AND', '&'),
      collectionId,
    };
  };

  const [suggestionScope, setSuggestionScope] = useState({ type: '', value: '', collectionId: '' });

  useEffect(() => {
    const scopeParamsFromQuery = getSuggestionScopeParamsFromQuery();
    const _isEqual = isEqual(suggestionScope, scopeParamsFromQuery);
    let result;

    if (
      'vendors' in searchQueries ||
      'product_types' in searchQueries ||
      'categories' in searchQueries ||
      'collections' in searchQueries
    ) {
      result = scopeParamsFromQuery;
    } else {
      result = { type: '', value: '', collectionId: '' };
    }

    if (!_isEqual) {
      setSuggestionScope(result);
    }
  }, [searchQueries]);

  const resetInputField = () => {
    setInputValue('');
    changeSearchTerm('');
    inputRef.current.focus();
  };

  const resetSearchParam = () => {
    const { type, value, collectionId } = suggestionScope;

    removeFilterParams(type, type === scopedFilterParams.collections ? collectionId : value);
  };

  const showSuggestionScope = suggestionScope?.type && suggestionScope?.value;

  return (
    <div className={clsNameMap.elm('wrapper')}>
      {getTermValueFromUrl(termKey).length > 0 ? (
        <h1 className={clsNameMap.elm('title')}>
          <Trans i18nKey={'search.resultHeader'} values={{ terms: getTermValueFromUrl(termKey) }} />
        </h1>
      ) : (
        <h1 className={clsNameMap.elm('title')}>
          <Trans i18nKey={'search.generalTitle'} values={{ terms: getTermValueFromUrl(termKey) }} />
        </h1>
      )}
      <form action='' method='get' className={clsNameMap.root()}>
        <input
          value={inputValue}
          ref={inputRef}
          type='search'
          name={termKey}
          placeholder={t('suggestion.searchBoxPlaceholder') || ''}
          className={clsNameMap.elm('input')}
          data-type='search-form'
          onChange={(e) => setInputValue(e.target.value)}
        />

        {inputValue !== '' && (
          <ButtonTextX
            value={'Clear'}
            modifiers={['clear-input-search-form']}
            onClick={resetInputField}
          />
        )}

        <Button type='submit' icon={IconSearch} buttonModifiers={['as-search']} />
      </form>

      {showSuggestionScope && (
        <div className={clsNameMap.elm('scope')}>
          <span className={clsNameMap.elm('scope.label')}>
            <strong>{searchTerm} </strong>
            {t('suggestion.in')}
          </span>

          <div className={clsNameMap.elm('scope.item')}>
            <span>{suggestionScope?.value}</span>
            <ButtonTextX value={'Clear'} onClick={resetSearchParam} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchForm;
