import './SuggestionQueries.scss';

import HeaderItem from '@components/HeaderItem';
import HighLightText from '@components/HighLightText';
import { setOnClickRecentSearches } from '@constants/instantSearchClick';
import type { Dict } from '@types';
import { createClsNameMap, generateSearchPageHref, mapModifiers } from '@utils';
import { useTranslation } from 'react-i18next';

export const clsNameMap = createClsNameMap({
  elements: {
    group: createClsNameMap(),
    list: createClsNameMap(),
    item: createClsNameMap({
      elements: {
        selected: createClsNameMap(),
      },
      modifiers: ['mobile-style1', 'suggestion', 'recent', 'most', 'popular', 'redirect'],
    }),
    header: createClsNameMap({
      modifiers: ['suggestion', 'recent', 'most', 'popular', 'redirect'],
    }),
    link: createClsNameMap(),
  },
})('suggestion-queries');

export type SuggestionQueriesTypes = 'suggestion' | 'recent' | 'popular' | 'redirect' | 'most';

export type SuggestionQueriesProps = {
  suggestions: Array<Dict | string> | undefined;
  suggestionsRedirect?: Dict;
  scopedSuggestions?: Dict;
  query: string;
  headerTitle: string;
  type?: SuggestionQueriesTypes;
  isHightLight?: boolean;
  isMobileStyle1?: boolean;
};

const SuggestionQueries = ({
  suggestions,
  suggestionsRedirect,
  scopedSuggestions,
  query,
  headerTitle,
  type,
  isHightLight = false,
  isMobileStyle1,
}: SuggestionQueriesProps) => {
  const { t } = useTranslation();

  if (!suggestions || suggestions?.length === 0) return null;

  const buildTitle = (suggestion: string | Dict) => {
    if (typeof suggestion === 'string') return suggestion;

    let title = '';
    if (typeof suggestion === 'object') {
      const scope = suggestion.scope;
      const inScope = scope ? ` ${t('suggestion.in')} ${scope}` : '';

      switch (type) {
        case 'suggestion':
          title = suggestion.title;
          break;
        case 'redirect':
          title = suggestion.title;
          break;
        default:
          title = suggestion.title + inScope;
      }

      return title;
    }

    return title;
  };

  const buildUrl = (item: string | Dict) => {
    if (typeof item === 'string') {
      if (suggestionsRedirect && suggestionsRedirect[item]) {
        return suggestionsRedirect[item];
      }

      return generateSearchPageHref(item);
    } else if (typeof item === 'object') {
      if (suggestionsRedirect && suggestionsRedirect[item.title]) {
        return suggestionsRedirect[item.title];
      }

      return generateSearchPageHref(item.title, item.extraParam);
    }
  };

  const modifierByType = () => {
    if (type) return { [type]: true };

    return {};
  };

  const renderLink = (suggestion: string | Dict) => {
    return (
      <a tabIndex={-1} href={buildUrl(suggestion)} className={clsNameMap.elm('link')}>
        <HighLightText
          text={buildTitle(suggestion)}
          highlight={query}
          enableHighLight={isHightLight}
        />
      </a>
    );
  };

  const buildScopedSuggestion = (
    i: number,
    suggestion: string | Dict,
    scopedSuggestions?: Dict
  ) => {
    const items: Array<Dict> = [];

    if (typeof suggestion === 'string') {
      if (
        scopedSuggestions &&
        suggestion in scopedSuggestions &&
        Object.keys(scopedSuggestions[suggestion]).length > 0
      ) {
        const currentSuggestion = scopedSuggestions[suggestion];

        Object.keys(currentSuggestion).forEach((paramKey) => {
          const values: Array<Dict> = [];

          if (typeof currentSuggestion[paramKey][0] === 'object') {
            currentSuggestion[paramKey].forEach((collection: Dict) => {
              values.push(collection);
            });
          } else {
            currentSuggestion[paramKey].forEach((text: string) => {
              values.push({ title: text });
            });
          }

          values.forEach((value: Dict) => {
            items.push({ type: paramKey, value });
          });
        });

        return (
          <ul key={`${suggestion}-${i}`} className={clsNameMap.elm('list')}>
            {items.map(({ type, value }) => {
              const extraParam = `${type}=${type === 'collections' ? value.id : value.title}`;
              const text = `${buildTitle(suggestion)} ${t('suggestion.in')} ${value.title}`;
              const title = buildTitle(value.title);

              return (
                <li
                  key={title}
                  className={mapModifiers(clsNameMap.item, {
                    ...modifierByType(),
                    'mobile-style1': isMobileStyle1,
                  })}
                  aria-label={'suggestions : ' + text}
                  aria-selected={'false'}
                  role={'option'}
                  data-title={text}
                  data-extra-param={extraParam}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOnClickRecentSearches(suggestion, extraParam, title);
                  }}
                >
                  <a
                    href={generateSearchPageHref(suggestion, extraParam)}
                    className={clsNameMap.elm('link')}
                  >
                    <HighLightText
                      text={`${t('suggestion.in')} ${title}`}
                      highlight={query}
                      enableHighLight={isHightLight}
                    />
                  </a>
                </li>
              );
            })}
          </ul>
        );
      }
    }

    return null;
  };

  return (
    <div className={clsNameMap.elm('group')}>
      {headerTitle && (
        <HeaderItem
          className={mapModifiers(clsNameMap.header, {
            ...modifierByType(),
          })}
          value={headerTitle}
        />
      )}
      <ul role='listbox' className={clsNameMap.elm('list')}>
        {suggestions.map((suggestion: string | Dict, i: number) => {
          return (
            <li
              key={i}
              className={mapModifiers(clsNameMap.item, {
                ...modifierByType(),
                'mobile-style1': isMobileStyle1,
              })}
              aria-label={'suggestions : ' + buildTitle(suggestion)}
              aria-selected={'false'}
              role={'option'}
              data-title={buildTitle(suggestion)}
              data-extra-param={typeof suggestion === 'object' && suggestion?.extraParam}
              onClick={() => {
                if (typeof suggestion === 'string') {
                  setOnClickRecentSearches(buildTitle(suggestion));
                } else {
                  setOnClickRecentSearches(
                    suggestion?.title,
                    suggestion?.extraParam,
                    suggestion?.scope
                  );
                }
              }}
            >
              {renderLink(suggestion)}
              {buildScopedSuggestion(i, suggestion, scopedSuggestions)}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SuggestionQueries;
