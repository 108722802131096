import {
  REQUEST_ID_FILTER_KEY,
  REQUEST_ID_MORE_INFORMATION_KEY,
  REQUEST_ID_RECOMMENDATION_KEY,
  REQUEST_ID_SAVE_INFO_REQUEST_KEY,
  REQUEST_ID_SEARCH_KEY,
  REQUEST_ID_SUGGEST_KEY,
} from '@constants/trackingEvent';
import type { ProductListItem } from '@providers/ProductListProvider';
import type { Dict } from '@types';
import { uniq } from 'lodash-es';

import type { QuickViewProductData } from '@/widgets/QuickView';

import { getLocalStorage, setLocalStorage } from './browser';
import { buildUrlWithLocalization } from './format';
import { isCartPage, isCollectionPage, isHomePage, isProductPage, isSearchPage } from './validate';

export const rebuildProductQuickViewDataFromShopifyData: (
  productData: any
) => QuickViewProductData = (productData) => {
  const options_with_values = productData.options.map((option: string, index: number) => {
    const values = uniq(
      productData.variants.map((variant: any) => variant[`option${index + 1}`])
    ).map((item) => ({ title: item }));

    return {
      values,
      name: option.toLowerCase(),
      label: option,
    };
  });

  const variants = productData.variants.map((variant: any) => {
    const merged_options = productData.options.map(
      (item: string, index: number) => `${item.toLocaleLowerCase()}:${variant.options[index]}`
    );

    const price = variant.price / 100;

    return {
      ...variant,
      merged_options,
      price,
    };
  });

  return { ...productData, options_with_values, variants };
};

export const rebuildProductItemDataFromShopifyData: (productData: any) => ProductListItem = (
  productData
) => {
  const variants = productData.variants.map((variant: any) => {
    const image = variant.featured_image?.src;

    const price = variant.price / 100;

    return {
      ...variant,
      price,
      image,
    };
  });

  return { ...productData, images_info: productData.media, variants };
};

export const generateSearchPageHref = (term = '', extraParam?: string) => {
  const extraSearchParam = extraParam ? `&${extraParam.replace(/&/g, 'AND')}` : '';

  return buildUrlWithLocalization(
    `search?q=${encodeURIComponent(term)}${extraSearchParam}`
  ).replace(/%20/g, '+');
};

/**
 * Generates a random unique session ID
 * @return {string} random unique ID
 */
export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getMaxValue = (localStorageKey: string, maxRecord = 20) => {
  let preValue = getLocalStorage(localStorageKey) || {};

  // KEEP max quality of record save
  if (Object.keys(preValue).length >= maxRecord) {
    const preValueTemp: Dict = {};
    const keys = Object.keys(preValue);
    const values = Object.values(preValue);

    for (let i = 0; i < maxRecord; i++) {
      preValueTemp[keys[i]] = values[i];
    }

    preValue = preValueTemp;
  }

  return preValue;
};

export const buildRequestIdForProducts = (
  requestId: string,
  products: Dict[],
  type: 'filter' | 'search' | 'suggest' | 'recommendation',
  isKeepPreData?: boolean
) => {
  const QUALITY_MAX_SAVE = 10;
  let REQUEST_ID_STORAGE_KEY = '';

  switch (type) {
    case 'filter':
      REQUEST_ID_STORAGE_KEY = REQUEST_ID_FILTER_KEY;
      break;

    case 'search':
      REQUEST_ID_STORAGE_KEY = REQUEST_ID_SEARCH_KEY;
      break;

    case 'suggest':
      REQUEST_ID_STORAGE_KEY = REQUEST_ID_SUGGEST_KEY;
      break;

    case 'recommendation':
      REQUEST_ID_STORAGE_KEY = REQUEST_ID_RECOMMENDATION_KEY;
      break;

    default:
      return;
  }

  if (requestId && Array.isArray(products) && products.length > 0) {
    const productIds = products.map((productItem: Dict) => Number(productItem.id || 0));

    let preValue = {};
    if (isKeepPreData) {
      preValue = getMaxValue(REQUEST_ID_STORAGE_KEY, QUALITY_MAX_SAVE) || {};
    }

    setLocalStorage(REQUEST_ID_STORAGE_KEY, {
      [requestId]: productIds,
      ...preValue,
    });
  }
};

export const buildRequestIdMoreInformation = (
  requestId: string,
  data: Dict,
  isKeepPreData?: boolean
) => {
  const QUALITY_MAX_SAVE = 10;

  if (requestId) {
    let preValue = {};
    if (isKeepPreData) {
      preValue = getMaxValue(REQUEST_ID_MORE_INFORMATION_KEY, QUALITY_MAX_SAVE) || {};
    }

    setLocalStorage(REQUEST_ID_MORE_INFORMATION_KEY, {
      [requestId]: data,
      ...preValue,
    });
  }
};

export const buildInfoWithRequestId = (
  requestId: string,
  data: {
    query_string: string;
    searchTerm?: string;
    action?: 'filter' | 'search' | 'recommend' | 'suggest';
  }
) => {
  const copyQueryString = data.query_string;

  if (data.query_string) {
    const search = data.query_string;

    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    data.query_string = '';

    hashes.forEach((hash, i) => {
      const [key, val] = hash.split('=');
      if (key === 'q') {
        if (!data.searchTerm) data.searchTerm = val;
        data.searchTerm = data.searchTerm?.replace('%', '');
        data.query_string += `${decodeURIComponent(key)}=${decodeURIComponent(data.searchTerm)}&`;
      }

      if (key === 'collection_scope' || key.startsWith('pf_')) {
        data.query_string += `${decodeURIComponent(key)}=${decodeURIComponent(val)}&`;
      }
    });

    if (data.query_string.length > 0) {
      // remove last is &
      data.query_string = data.query_string.substring(0, data.query_string.length - 1);
    }
  }

  // get origin query string if it not transfer data
  if (data.query_string === '') data.query_string = copyQueryString;

  const QUALITY_MAX_SAVE = 20;

  if (requestId && data) {
    const preValue = getMaxValue(REQUEST_ID_SAVE_INFO_REQUEST_KEY, QUALITY_MAX_SAVE) || {};

    setLocalStorage(REQUEST_ID_SAVE_INFO_REQUEST_KEY, {
      [requestId]: data,
      ...preValue,
    });
  }
};

export const getFilterAvoidStickyHeader = (fromSetting: string[], fromDefault: string) => {
  if (fromSetting?.length > 0) {
    return fromSetting.toString();
  }

  return fromDefault;
};

/**
 * get current page
 */
export const getCurrentPage = () => {
  let currentPage = '';

  switch (true) {
    case isCollectionPage():
      currentPage = 'collection_page';
      break;

    case isSearchPage():
      currentPage = 'search_page';
      break;

    case isProductPage():
      currentPage = 'product_page';
      break;

    case isCartPage():
      currentPage = 'cart_page';
      break;

    case isHomePage():
      currentPage = 'home_page';
      break;

    default:
      break;
  }

  return currentPage;
};
