import './styles.scss';

import { clsNameMapFactory, createClassnameNameSpace, mapModifiers } from '@boost-sd/classnames';
import { createRoot } from 'react-dom/client';

import { shopifyFormatMoney } from './helpers';

type ProductFilterProps = {
  collection: any;
  products: any[];
  pagination: any;
  productsPerPage: number;
  moneyFormat?: string;
  defaultSortBy?: string;
  sortBy?: string;
  sortOptions?: Array<Array<string>>;
};

const clsNames = createClassnameNameSpace({ prefix: 'boost-sd__' });
const clsNamesMap = clsNameMapFactory(clsNames);

const clsNameMap = clsNamesMap({
  elements: {
    container: clsNamesMap({}),
    header: clsNamesMap({}),
    title: clsNamesMap({}),
    layout: clsNamesMap({}),
    'product-list': clsNamesMap({}),
    'product-item': clsNamesMap({}),
    'product-link': clsNamesMap({}),
    'product-media': clsNamesMap({}),
    'product-image': clsNamesMap({}),
    'product-labels': clsNamesMap({}),
    'product-label': clsNamesMap({
      modifiers: ['on-sale', 'sold-out'],
    }),
    'product-title': clsNamesMap({}),
    'product-price-wrapper': clsNamesMap({}),
    'product-price': clsNamesMap({
      modifiers: ['on-sale', 'compare-at-price'],
    }),
    button: clsNamesMap({}),
    'button-text': clsNamesMap({}),
    pagination: clsNamesMap({}),
    'pagination-icon': clsNamesMap({}),
    'pagination-item': clsNamesMap({
      modifiers: ['current-page'],
    }),
    'pagination-link': clsNamesMap({}),
    'placeholder-image': clsNamesMap({}),
    'empty-product': clsNamesMap({}),
    'sort-by': clsNamesMap({}),
    'sort-by-label': clsNamesMap({}),
    sort: clsNamesMap({}),
    'sort-select': clsNamesMap({}),
    'sort-arrow': clsNamesMap({}),
    toolbar: clsNamesMap({}),
    'product-count': clsNamesMap({}),
    badges: clsNamesMap({}),
    badge: clsNamesMap({}),
  },
})('fallback');

const ProductFilter = ({
  products,
  collection,
  pagination,
  moneyFormat,
  defaultSortBy,
  sortBy,
  sortOptions,
}: ProductFilterProps) => {
  const handleAddToCart = (variantId: string) => {
    fetch('/cart/add.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: JSON.stringify({
        id: variantId,
        quantity: 1,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Product added to cart:', data);
        window.location.href = '/cart';
      })
      .catch((error) => {
        console.error('Error adding product to cart:', error);
      });
  };

  const productUrl = (product: any) => `/products/${product.handle}`;

  // create modified parts array by replacing `&hellip;` with `...`
  const modifiedParts = pagination.parts.map(
    (part: { title: string; url: string; is_link: boolean }) => {
      if (part.title === '&hellip;') {
        return {
          ...part,
          title: '...',
        };
      }
      return part;
    }
  );

  const handleChangeSortOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const sortOption = e.target.value;

    // Remove old param sort_by
    const oldUrl = new URL(window.location.href);
    const searchParams = oldUrl.searchParams;
    searchParams.delete('sort_by');
    searchParams.delete('page');

    // Add new param sort_by
    searchParams.set('page', '1');
    searchParams.set('sort_by', sortOption);

    window.location.href = `${oldUrl.origin}${oldUrl.pathname}?${searchParams.toString()}`;
  };

  const renderToolbar = () => (
    <div className={clsNameMap.elm('toolbar')}>
      <div className={clsNameMap.elm('product-count')}>
        {pagination.items} {pagination.items > 1 ? 'products' : 'product'}
      </div>
      <div className={clsNameMap.elm('sort-by')}>
        <div className={clsNameMap.elm('sort-by-label')}>
          <label htmlFor='SortBy'>Sort by:</label>
        </div>
        <div className={clsNameMap.elm('sort')}>
          <select
            className={clsNameMap.elm('sort-select')}
            name='sort_by'
            id='SortBy'
            onChange={handleChangeSortOption}
            defaultValue={sortBy || defaultSortBy}
          >
            {sortOptions?.map((item) => (
              <option key={item[0]} value={item[0]}>
                {item[1]}
              </option>
            ))}
          </select>
          <svg
            className={clsNameMap.elm('sort-arrow')}
            aria-hidden='true'
            focusable='false'
            viewBox='0 0 10 6'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z'
              fill='currentColor'
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );

  const renderProduct = (product: any) => (
    <div className={clsNameMap.elm('product-item')} key={product.id}>
      <a className={clsNameMap.elm('product-link')} href={productUrl(product)}>
        <div className={clsNameMap.elm('product-media')}>
          <div className={clsNameMap.elm('product-labels')}>
            {product.compare_at_price_max > product.price && (
              <span className={mapModifiers(clsNameMap['product-label'], ['on-sale'])}>Sale</span>
            )}
            {product.available === false && (
              <span className={mapModifiers(clsNameMap['product-label'], ['sold-out'])}>
                Sold Out
              </span>
            )}
          </div>
          {product.featured_image ? (
            <img className={clsNameMap.elm('product-image')} src={product.featured_image} alt='' />
          ) : (
            <div className={clsNameMap.elm('placeholder-image')}>No image</div>
          )}
        </div>
        <h2 className={clsNameMap.elm('product-title')}>{product.title}</h2>
        <div className={clsNameMap.elm('product-price-wrapper')}>
          {product.compare_at_price_max > product.price ? (
            <>
              <span className={mapModifiers(clsNameMap['product-price'], ['on-sale'])}>
                {shopifyFormatMoney(product.price, moneyFormat)}
              </span>
              <span className={mapModifiers(clsNameMap['product-price'], ['compare-at-price'])}>
                {shopifyFormatMoney(product.compare_at_price_max, moneyFormat)}
              </span>
            </>
          ) : (
            <span className={clsNameMap.elm('product-price')}>
              {shopifyFormatMoney(product.price, moneyFormat)}
            </span>
          )}
        </div>
      </a>
      {product.available === false ? (
        <button className={clsNameMap.elm('button')} type='button' disabled>
          <span className={clsNameMap.elm('button-text')}>Sold Out</span>
        </button>
      ) : product?.variants?.length === 1 ? (
        <button
          className={clsNameMap.elm('button')}
          type='button'
          onClick={() => handleAddToCart(product.variants[0].id)}
        >
          <span className={clsNameMap.elm('button-text')}>Add to Cart</span>
        </button>
      ) : (
        <a className={clsNameMap.elm('button')} href={productUrl(product)}>
          <span className={clsNameMap.elm('button-text')}>Select Options</span>
        </a>
      )}
    </div>
  );

  const renderPage = (page: any) => (
    <div className={clsNameMap.elm('product-item')} key={page.title}>
      <a className={clsNameMap.elm('product-link')} href={page.url}>
        <div className={clsNameMap.elm('product-media')}>
          <div className={clsNameMap.elm('placeholder-image')}>
            <span />
          </div>
        </div>
        <div className={clsNameMap.elm('badges')}>
          <span className={clsNameMap.elm('badge')}>Page</span>
        </div>
        <h2 className={clsNameMap.elm('product-title')}>{page.title}</h2>
      </a>
    </div>
  );

  const renderArticle = (article: any) => (
    <div className={clsNameMap.elm('product-item')} key={article.title}>
      <a className={clsNameMap.elm('product-link')} href={article.url}>
        <div className={clsNameMap.elm('product-media')}>
          {article.image ? (
            <img className={clsNameMap.elm('product-image')} src={article.image} alt='' />
          ) : (
            <div className={clsNameMap.elm('placeholder-image')}>No image</div>
          )}
        </div>
        <div className={clsNameMap.elm('badges')}>
          <span className={clsNameMap.elm('badge')}>Blog</span>
        </div>
        <h2 className={clsNameMap.elm('product-title')}>{article.title}</h2>
      </a>
    </div>
  );

  return (
    <section className={clsNameMap.elm('container')}>
      <div className={clsNameMap.elm('header')}>
        <h1 className={clsNameMap.elm('title')}>{collection.title}</h1>
      </div>
      <div className={clsNameMap.elm('layout')}>
        {products.length > 0 ? (
          <>
            {renderToolbar()}

            <div className={clsNameMap.elm('product-list')}>
              {products.map((item) => {
                if (item.resultType === 'page') return renderPage(item);
                if (item.resultType === 'article') return renderArticle(item);
                return renderProduct(item);
              })}
            </div>

            <ul className={clsNameMap.elm('pagination')}>
              {pagination.previous && (
                <li className={clsNameMap.elm('pagination-item')}>
                  <a href={pagination.previous.url} className={clsNameMap.elm('pagination-link')}>
                    <svg
                      className={clsNameMap.elm('pagination-icon')}
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 7 12'
                      height='12'
                      width='7'
                    >
                      <path
                        d='M6.80474 0.528514C6.54439 0.268165 6.12228 0.268165 5.86193 0.528514L0.861929 5.52851C0.601579 5.78886 0.601579 6.21097 0.861929 6.47132L5.86193 11.4713C6.12228 11.7317 6.54439 11.7317 6.80474 11.4713C7.06509 11.211 7.06509 10.7889 6.80474 10.5285L2.27614 5.99992L6.80474 1.47132C7.06509 1.21097 7.06509 0.788864 6.80474 0.528514Z'
                        clipRule='evenodd'
                        fillRule='evenodd'
                      ></path>
                    </svg>
                  </a>
                </li>
              )}
              {modifiedParts.map((part: { title: string; url: string; is_link: boolean }) =>
                part.is_link ? (
                  <li key={part.title} className={clsNameMap.elm('pagination-item')}>
                    <a href={part.url} className={clsNameMap.elm('pagination-link')}>
                      {part.title}
                    </a>
                  </li>
                ) : (
                  <li
                    key={part.title}
                    className={mapModifiers(clsNameMap['pagination-item'], {
                      'current-page': part.title === pagination.current_page,
                    })}
                  >
                    {part.title}
                  </li>
                )
              )}
              {pagination.next && (
                <li className={clsNameMap.elm('pagination-item')}>
                  <a href={pagination.next.url} className={clsNameMap.elm('pagination-link')}>
                    <svg
                      className={clsNameMap.elm('pagination-icon')}
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 7 12'
                      height='12'
                      width='7'
                    >
                      <path
                        d='M0.195262 0.528514C0.455612 0.268165 0.877722 0.268165 1.13807 0.528514L6.13807 5.52851C6.39842 5.78886 6.39842 6.21097 6.13807 6.47132L1.13807 11.4713C0.877722 11.7317 0.455612 11.7317 0.195262 11.4713C-0.0650874 11.211 -0.0650874 10.7889 0.195262 10.5285L4.72386 5.99992L0.195262 1.47132C-0.0650874 1.21097 -0.0650874 0.788864 0.195262 0.528514Z'
                        clipRule='evenodd'
                        fillRule='evenodd'
                      ></path>
                    </svg>
                  </a>
                </li>
              )}
            </ul>
          </>
        ) : (
          <div className={clsNameMap.elm('empty-product')}>No products found</div>
        )}
      </div>
    </section>
  );
};

function render() {
  if (window.boostSDFallback) {
    const {
      productsPerPage = 24,
      products,
      productFilterSelector,
      collection,
      pagination,
      moneyFormatWithCurrency,
      defaultSortBy,
      sortBy,
      sortOptions,
    } = window.boostSDFallback;

    if (!pagination) return;

    const element = document.querySelector(
      productFilterSelector || '.boost-sd__product-filter-fallback'
    );

    if (!element) return;

    const root = createRoot(element);

    root.render(
      <ProductFilter
        products={products}
        productsPerPage={productsPerPage}
        collection={collection}
        pagination={pagination}
        moneyFormat={moneyFormatWithCurrency}
        defaultSortBy={defaultSortBy}
        sortBy={sortBy}
        sortOptions={sortOptions}
      />
    );
  }
}

window.addEventListener('boost-sd-enable-product-filter-fallback', render);

if (!window.boostSDAppConfig || window.boostSDFallback?.enableProductFilterFallbackOnLoad) {
  render();
}

export * from './helpers';
