import type { RegistryComponentProps } from '@boost-sd/components-registry';
import { registryComponent } from '@boost-sd/components-registry/registry';
import type { DeepPartial } from '@types';
import { merge } from 'lodash-es';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useImperativeHandle, useState } from 'react';

import type { FilterSettings } from '../types';

const FilterSettingsContext = createContext<FilterSettings | null>(null);

export type FilterSettingsProviderProps = PropsWithChildren<unknown>;

const defaultSettings: FilterSettings = {
  /**
   * FilterTreeSettings
   */
  enableFilter: true,
  filterTreeMobileStyle: 'style2',
  filterTreeMobileFullWidth: false,
  filterTreeVerticalStyle: 'style-default',
  filterTreeHorizontalStyle: 'style1',
  filterHorizontalColumn: '1',
  filterHorizontalOptionsAlign: 'left',
  filterLayout: 'vertical', // 'horizontal' | 'vertical',
  /**
   * FilterOptionCollapseSettings
   */
  collapseOnPCByDefault: false,
  collapseOnMobileByDefault: false,
  keepScrollState: true,
  keepToggleState: true,
  keepTabOpenState: false,
  /**
   * Filter Sticky
   */
  stickyFilterOnDesktop: false,
  stickyFilterOnMobile: false,

  /**
   * Filter View More
   */
  startViewMore: { list: 5, box: 3, swatch: 10 },
  startViewMoreH: { list: 10, box: 20, swatch: 10 },

  // Filter option - Range slider
  removePriceDecimal: true, // Remove '.00'
  // rangeSliderMoneyFormat: '', // Take shop's moneyFormat if left empty.
  // oneValueRangeSlider: false, // Show range slider when there's one value
  // rangeSlidersStyle3: [], // List of filter option id to turn into range slider style3 (text box on bottom)
  // rangeSlidersSingleHandle: [], // List of filter option id to turn into range slider style4: single handle
  // advancedRangeSliders: [], // List of filter option id to turn into range slider. Ex: ['pf_t_size', 'pf_t_weight']
  shortenPipsRange: false, // Shorten range slider label. Example: 1000 -> 1k
  formatPipsRange: [
    { node: 1e3, symbol: 'K', fix: 0, suffix: false },
    { node: 1e6, symbol: 'M', fix: 2, suffix: false },
    // { node: 1e9, symbol: 'B', fix: 2, suffix: false }, // add new - not in requirement
    // { node: 1e12, symbol: 'T', fix: 2, suffix: false }, // add new - not in requirement
    // { node: 1e18, symbol: 'E', fix: 2, suffix: false }, // add new - not in requirement
  ], // Fix: number of decimal: 1k, 1.2k, 1.23k. Suffix: 1.2k (suffix = true) or 1k2 (suffix = false).
  // enable3rdCurrencySupport: false,

  /**
   * Filter Options Settings
   */
  showFilterOptionCount: true, // Show product count
  showRefineBy: true,
  productAndVariantAvailable: false,
  showOutOfStockOption: false, // Show Out of Stock filter option. Show all irrelevant values (product count = 0)
  requestInstantly: false, // On Horizontal, make API request when clicking on filter item (no need to click apply)
  //Mobile Button
  changeMobileButtonLabel: false, // Change label when clicking Refine By button on mobile
  showSingleOption: true, // True = Hide filter options with only one filter option value.

  // Address bar URL
  urlScheme: 2, // 0: dont change url, 1: example 'color=red&color=blue', 2: example 'color=red,blue'
  isShortenUrlParam: false, // Use the 'shortenUrlParamList' to shorten param
  shortenUrlParamList: [], // List of ['filter_option_id:short_id']. Example: ['pf_otp_color:color', 'pf_t_size:size']. Make sure the values are unique, and don't contain special characters.

  // Scroll to top
  activeScrollToTop: false, // Display Scroll to top button
  styleScrollToTop: 'style1', // Color of Scroll to top button: style1(black), style2(gray)

  /* Filter Product list Settings */
  productAvailable: false, // Return products in stock or not from the first load
  variantAvailable: false, // Return variants in stock or not from the first load
  availableAfterFiltering: false, // Return products & variants in stock when filter option params exist on the url
  showVariantImageBasedOnSelectedFilter: '', // Filter option id to change variant image based on that. Ex: 'pf_opt_color'

  /** Filter Style Settings */
  style: {
    filterMobileButtonBackgroundColor: '',
    filterMobileButtonFontFamily: '',
    filterMobileButtonFontSize: '',
    filterMobileButtonFontTransform: '',
    filterMobileButtonFontWeight: '',
    filterMobileButtonTextColor: '',
    filterOptionFontFamily: '',
    filterOptionFontSize: '',
    filterOptionTextColor: '',
    filterTitleFontFamily: '',
    filterTitleFontSize: '',
    filterTitleFontTransform: 'none',
    filterTitleFontWeight: '',
    filterTitleTextColor: '',
  },

  /*** swatch settings */
  swatch_settings: {},

  /**
   * Other Settings
   */
  filterEverywhereCollectionId: 0,
  limit: 24,
  showVariantsAsProduct: false,
  priceMode: '',
  tagMode: '',
  sortingAvailableFirst: false,
  vendorParam: 'pf_v_vendor',
  typeParam: 'pf_pt_product_type',
  filterPrefixParam: 'pf_',
  sortManualValues: false,
  // Loading icon
  showLoading: false, // Show Loading icon when requesting API.
  showMobileLoading: false, // Show Loading icon on mobile.

  // new settings
  precisionFormatLabelSlider: 0, // precision of format Label Slider 0,1,2.. default 0
  precisionFormatLabelPriceList: 2, // precision of format Label price type List 0,1,2.. default 2
  filterAvoidStickyHeader: [], // list string ex: ['#shopify-section-header', '#SiteHeader']
};

export const FilterSettingsProvider = registryComponent(
  'FilterSettingsProvider',
  ({
    children,
    helpersRef,
  }: RegistryComponentProps<
    FilterSettingsProviderProps,
    {
      reloadSettings: (settings?: DeepPartial<FilterSettings>) => void;
      getSettings: () => FilterSettings;
    }
  >) => {
    const [settings, setSettings] = useState<FilterSettings>(
      merge({}, defaultSettings, window.boostSDAppConfig?.filterSettings || {})
    );

    /**
     * Log for debug and fix bug
     */
    console.log('Filter Settings: ', settings);

    useImperativeHandle(helpersRef, () => ({
      getSettings() {
        return settings;
      },
      reloadSettings(settings) {
        setSettings(
          merge({}, defaultSettings, window.boostSDAppConfig?.filterSettings || {}, settings || {})
        );
      },
    }));

    return (
      <FilterSettingsContext.Provider value={settings}>{children}</FilterSettingsContext.Provider>
    );
  }
);

export const useFilterSettings = () => {
  const context = useContext(FilterSettingsContext);
  if (!context) {
    throw Error('Use useFilterSettings in FilterSettingsProvider');
  }

  return context;
};
