import './SWSearchWidgetInit.scss';

import { useForceRender, useMountEffect } from '@boost-sd/core-js';
import useGeneralSettings from '@hooks/useGeneralSettings';
import { BOOST_SD_SEARCH_RESULTS_CACHE } from '@hooks/useLocalStorage';
import { useSearchSettings } from '@providers/SearchProvider';
import { createClsNameMap, getLocalStorage, setLocalStorage } from '@utils';
import { useEffect, useState } from 'react';

import SearchBar, { idsNameMap as searchBarIds } from '@/widgets/SWSearchBar';
import SearchInput from '@/widgets/SWSearchInput';

import SWSearchContentResult from '../SWSearchContentResult';

export const clsNameMap = createClsNameMap({
  elements: {
    wrapper: createClsNameMap({}),
    input: createClsNameMap({}),
    enabled: createClsNameMap({}),
  },
})('search-widget-init');

export type InstantSearchInitProps = {
  hasSearchModal?: boolean;
};

const InstantSearchInit = ({ hasSearchModal = true }: InstantSearchInitProps) => {
  const {
    settings: { enableInstantSearch },
  } = useSearchSettings();

  const {
    generalSettings: { termKey },
  } = useGeneralSettings();

  // ex: flow, icon theme
  const [initSpecialTheme, setInitSpecialTheme] = useState<boolean>(false);
  const forceRender = useForceRender();

  const registerInstantForInputs = (inputs: NodeListOf<Element>) => {
    inputs.forEach((input: Element, i) => {
      input.id = `${clsNameMap.elm('input')}-${i}`;
      input.classList.add(clsNameMap.elm('input'));
    });

    document.body.classList.add(clsNameMap.elm('enabled'));
  };

  useEffect(() => {
    if (enableInstantSearch) {
      // reset searchResultsCache each reload page
      const searchResultsCache = getLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE);
      if (searchResultsCache) {
        setLocalStorage(BOOST_SD_SEARCH_RESULTS_CACHE, {});
      }

      // flow, icon theme
      const searchButtons = document.querySelectorAll(
        `.header-section--wrapper .search-button,
         .header-section .slideout__trigger-search
        `
      );

      if (searchButtons.length > 0) {
        Array.from(searchButtons).forEach((searchButton) => {
          searchButton.addEventListener('click', () => {
            // wait search form show
            setTimeout(() => {
              setInitSpecialTheme(true);

              // flow, icon theme
              const closeButtons = document.querySelectorAll(
                `.drawer--right .drawer__header .icon-fallback-text,
                 .slideout__trigger--close
                `
              );

              Array.from(closeButtons).forEach((closeButton) => {
                closeButton.addEventListener(
                  'click',
                  () => {
                    setInitSpecialTheme(false);
                  },
                  true
                );
              });
            }, 200);
          });
        });
      }
    }
  }, []);

  const initMultiInstantSearch = (type = 'default') => {
    if (initSpecialTheme && type === 'default') return <></>;

    const inputs = document.querySelectorAll(
      `input[name="${termKey}"]:not([data-disable-instant-search])`
    );

    registerInstantForInputs(inputs);

    const numberOfInput = document.querySelectorAll(
      `input[name="${termKey}"]:not([data-disable-instant-search]).${clsNameMap.elm('input')}`
    );
    if (numberOfInput.length === 0) return null;
    return Array.from(numberOfInput).map((input, i) => {
      const thisSearchboxWrapper = `${clsNameMap.elm('wrapper')}-${i}`;
      const inputClass = input.className;

      return (
        <div
          id={thisSearchboxWrapper}
          key={`${thisSearchboxWrapper}-${inputClass}-${i}`}
          className={clsNameMap.elm('wrapper')}
        >
          <SearchInput
            id={input.id} // id input of theme in header
            idSuggestionResults={thisSearchboxWrapper}
            hasSearchModal={hasSearchModal === false ? false : true}
          />
          <SWSearchContentResult id={thisSearchboxWrapper} />
        </div>
      );
    });
  };

  useMountEffect(function listenBindSearchInputEvent() {
    window.addEventListener('bind-search-input', forceRender);

    return () => {
      window.removeEventListener('bind-search-input', forceRender);
    };
  });

  return enableInstantSearch ? (
    <>
      {/* Create input of boost  */}
      <SearchBar />

      {/* NOTE: Bind event to input of boost for all page  */}
      <SearchInput
        id={searchBarIds.elm('input')} // id input of boost
        idSuggestionResults={searchBarIds.elm('autocomplete.wrapper')} // id suggestion wrapper in SearchBar component
        isOwnerInput={true} // = true: if input of boost
      />

      {/* init for default  */}
      {initMultiInstantSearch('default')}

      {/* init for some theme special : flow, icon form render after click search button  */}
      {initSpecialTheme && initMultiInstantSearch('special')}
    </>
  ) : null;
};

export default InstantSearchInit;
