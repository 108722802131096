import { isEqual } from 'lodash-es';
import { useCallback, useRef } from 'react';

type Subscriber = () => void;

export type ExternalStore<State> = {
  subscribe: (subscriber: Subscriber) => () => void;
  getSnapshot: () => State;
};

export type StateAsExternalStoreOptions = {
  deepCompare?: boolean;
};

const useStateAsExternalStore = <State>(
  state: State,
  options: StateAsExternalStoreOptions = { deepCompare: true }
): ExternalStore<State> => {
  const prevState = useRef<State | null>(null);
  const subscriberRef = useRef<Subscriber | null>(null);

  const stateHasChanged = options.deepCompare
    ? !isEqual(prevState.current, state)
    : prevState.current !== state;

  if (stateHasChanged) {
    prevState.current = state;
    if (subscriberRef.current) {
      subscriberRef.current();
    }
  }

  const subscribe = useCallback((subscriber: Subscriber) => {
    subscriberRef.current = subscriber;

    return () => {
      subscriberRef.current = null;
    };
  }, []);

  const getSnapshot = useCallback(() => {
    return state;
  }, [state]);

  return {
    subscribe,
    getSnapshot,
  };
};

export default useStateAsExternalStore;
